import React, {useEffect} from 'react'
import styled from 'styled-components'
import {useTranslation} from "react-i18next";

const Safety = () => {
    const { t } = useTranslation();

    useEffect(() => {
        document.title = t(`communityGuidelines.title`)
    }, []);

    return (
        <Container>
            <Title>{t(`title`)} – {t(`safety.title`)}</Title>
            <Subtitle2>{t(`safety.onlineSafety`)}</Subtitle2>
            <ul>
                <li>{t(`safety.money`)}. {t(`safety.moneyDescription`)}</li>
                <li>{t(`safety.secureData`)}. {t(`safety.secureDataDescription`)}</li>
                <li>{t(`safety.communicate`)}. {t(`safety.communicateDescription`)}</li>
                <li>{t(`safety.usersOthers`)}. {t(`safety.usersOthersDescription`)}</li>
                <li>{t(`safety.report`)}. {t(`safety.reportDescription`)}:
                    <ul>
                        <li>{t(`safety.report1`)};</li>
                        <li>{t(`safety.report2`)};</li>
                        <li>{t(`safety.report3`)};</li>
                        <li>{t(`safety.report4`)};</li>
                        <li>{t(`safety.report5`)}.</li>
                    </ul>

                </li>

            </ul>
        </Container>
    )
}

export default Safety


const Container = styled.div`
        margin: 0 auto;
        width: 70%;
        padding: 24px 24px;
        background: white;
        text-wrap: wrap;
        ul li {
                color: #505965;
                margin-top: 12px;
                margin-bottom: 12px;
                font-size: 18px;
        }

  li::marker {
    content: counter(list-item) '. ';
    color: #505965;
    font-weight: bold;
  }
`

const Title = styled.h1`
  color: #21262e;
`
const Subtitle2 = styled.h2`
  color: #21262e;
`
