import React, {useEffect} from 'react'
import styled from 'styled-components'
import {Trans, useTranslation} from "react-i18next";

const TermsOfUse = () => {
        const { t } = useTranslation();

        useEffect(() => {
                document.title = t(`communityGuidelines.title`)
        }, []);

        return (
            <Container>
                    <Title>{t(`title`)} – {t(`communityGuidelines.title`)}</Title>
                    <Text>{t(`communityGuidelines.welcome`)}</Text>
                    <Text>{t(`communityGuidelines.description`)}</Text>
                    <Subtitle2>{t(`communityGuidelines.rules`)}:</Subtitle2>
                <ul>
                    <li>
                        <Trans
                            components={{ bold: <Text bold />, text: <Text /> }}
                            i18nKey={"communityGuidelines.item1"}
                        />
                    </li>
                    <li>
                        <Trans
                            components={{ bold: <Text bold />, text: <Text /> }}
                            i18nKey={"communityGuidelines.item2"}
                        />
</li>
                    <li> <Trans
                        components={{ bold: <Text bold />, text: <Text /> }}
                        i18nKey={"communityGuidelines.item3"}
                    /></li>
                    <li> <Trans
                        components={{ bold: <Text bold />, text: <Text /> }}
                        i18nKey={"communityGuidelines.item4"}
                    /></li>
                    <li> <Trans
                        components={{ bold: <Text bold />, text: <Text /> }}
                        i18nKey={"communityGuidelines.item5"}
                    /></li>
                    <li> <Trans
                        components={{ bold: <Text bold />, text: <Text /> }}
                        i18nKey={"communityGuidelines.item6"}
                    /></li>
                    <li> <Trans
                        components={{ bold: <Text bold />, text: <Text /> }}
                        i18nKey={"communityGuidelines.item7"}
                    /></li>
                    <li> <Trans
                        components={{ bold: <Text bold />, text: <Text /> }}
                        i18nKey={"communityGuidelines.item8"}
                    /></li>
                    <li> <Trans
                        components={{ bold: <Text bold />, text: <Text /> }}
                        i18nKey={"communityGuidelines.item9"}
                    /></li>
                    <li> <Trans
                        components={{ bold: <Text bold />, text: <Text /> }}
                        i18nKey={"communityGuidelines.item10"}
                    /></li>
                    <li> <Trans
                        components={{ bold: <Text bold />, text: <Text /> }}
                        i18nKey={"communityGuidelines.item11"}
                    /></li>
                    <li> <Trans
                        components={{ bold: <Text bold />, text: <Text /> }}
                        i18nKey={"communityGuidelines.item12"}
                    /></li>
                </ul>
            </Container>
        )
}

export default TermsOfUse


const Container = styled.div`
        margin: 0 auto;
        width: 70%;
        padding: 24px 24px;
        background: white;
        text-wrap: wrap;
        ul li {
                color: #505965;
                margin-top: 12px;
                margin-bottom: 12px;
                font-size: 18px;

        }
  li::marker {
    content: counter(list-item) '. ';
    color: #505965;
    font-weight: bold;
  }
`

const Text = styled.p<{bold?: boolean}>`
  ${(props) =>
          props.bold &&
          `
font-weight: 700;
 `};
  color: #505965;
  font-size: 18px;
`

const Title = styled.h1`
  color: #21262e;
`
const Subtitle2 = styled.h2`
  color: #21262e;
`
const Subtitle3 = styled.h3`
  color: #21262e;
`
