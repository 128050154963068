import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import Backend from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';

i18n
    .use(Backend)
    .use(LanguageDetector)
    .use(initReactI18next)
    .init({

        lng: 'ru',
        fallbackLng: 'ru',
        preload: ['ru', 'en', 'fr', 'es', 'de', 'pt'],
        ns: ['translation'],
        defaultNS: 'translation',
        backend: {
            loadPath: "/locales/{{lng}}/{{ns}}.json",
        },
        debug: false,
        interpolation: {
            escapeValue: false,
        }
    }, (err: any) => {
        if (err) return console.error('Ошибка при инициализации 18next: ', err);
    },).then(() => console.error('Успех инициализации 18next'));


export default i18n;
