import React, {useEffect} from 'react'
import {useTranslation} from "react-i18next";
import styled from "styled-components";

const PrivacyPolicy = () => {
       const { t } = useTranslation();

       useEffect(() => {
        document.title = `${t("privacyPolicy.title")}`
    }, []);

    return (
       <Container>
           <Title>{t(`title`)} – {t(`privacyPolicy.title`)}</Title>
           <Text>{t(`privacyPolicy.lastUpdated`, { lastUpdated: `20 мая 2024 г.` })}</Text>
           <Text>{t(`privacyPolicy.description`)}</Text>
           <Subtitle2>1. {t(`privacyPolicy.collectionOfInformation.interpretationAndDefinitions`)}</Subtitle2>
           <Subtitle3>{t(`privacyPolicy.collectionOfInformation.interpretation`)}</Subtitle3>
           <Text>{t(`privacyPolicy.collectionOfInformation.interpretationDescription`)}</Text>
           <Subtitle3>{t(`privacyPolicy.collectionOfInformation.definitions`)}</Subtitle3>
           <ul>
               <li>{t(`privacyPolicy.collectionOfInformation.definitionsDescription`)}:
                   <ul>
                       <li><b>{t(`account`)}</b> {t(`privacyPolicy.collectionOfInformation.definition1`)}</li>
                       <li><b>{t(`affiliate`)}</b> {t(`privacyPolicy.collectionOfInformation.definition2`)}</li>
                       <li><b>{t(`application`)}</b> {t(`privacyPolicy.collectionOfInformation.definition3`)}</li>
                       <li><b>{t(`company`)}</b> {t(`privacyPolicy.collectionOfInformation.definition4`)}</li>
                       <li><b>{t(`device`)}</b> {t(`privacyPolicy.collectionOfInformation.definition5`)}</li>
                       <li><b>{t(`personalData`)}</b> — {t(`privacyPolicy.collectionOfInformation.definition6`)}</li>
                       <li><b>{t(`service`)}</b> {t(`privacyPolicy.collectionOfInformation.definition7`)}</li>
                       <li><b>{t(`serviceProvider`)}</b> {t(`privacyPolicy.collectionOfInformation.definition8`)}</li>
                       <li><b>{t(`socialMediaService`)}</b> — {t(`privacyPolicy.collectionOfInformation.definition9`)}</li>
                       <li><b>{t(`usageData`)}</b> — {t(`privacyPolicy.collectionOfInformation.definition10`)}</li>
                       <li><b>{t(`You`)}</b> {t(`privacyPolicy.collectionOfInformation.definition11`)}</li>
                   </ul>
               </li>
           </ul>
           <Subtitle2>2. {t(`privacyPolicy.collectionOfInformation.title`)}</Subtitle2>
           <Text>{t(`privacyPolicy.collectionOfInformation.description`)}</Text>
           <Subtitle3>{t(`privacyPolicy.collectionOfInformation.informationGive`)}</Subtitle3>
           <Text>{t(`privacyPolicy.collectionOfInformation.informationGiveDescription`)}</Text>

        <ul>
         <li>{t(`privacyPolicy.collectionOfInformation.itemGive1`)}</li>
         <li>{t(`privacyPolicy.collectionOfInformation.itemGive2`)}</li>
         <li>{t(`privacyPolicy.collectionOfInformation.itemGive3`)}</li>
         <li>{t(`privacyPolicy.collectionOfInformation.itemGive4`)}</li>
        </ul>


        <Subtitle3>{t(`privacyPolicy.collectionOfInformation.informationGiveOthers`)}</Subtitle3>
        <Text>{t(`privacyPolicy.collectionOfInformation.informationGiveOthersDescription`)}</Text>

         <ul>
            <li>{t(`privacyPolicy.collectionOfInformation.socialMedia`)}: {t(`privacyPolicy.collectionOfInformation.itemGive5`)}</li>
            <li>{t(`privacyPolicy.collectionOfInformation.otherPartners`)}: {t(`privacyPolicy.collectionOfInformation.itemGive6`)}</li>
         </ul>

        <Subtitle3>{t(`privacyPolicy.collectionOfInformation.automaticInfo`)}</Subtitle3>
        <Text>{t(`privacyPolicy.collectionOfInformation.automaticInfoDescription`)}</Text>

        <ul>
         <li>{t(`privacyPolicy.collectionOfInformation.itemGive7`)}</li>
         <li>{t(`privacyPolicy.collectionOfInformation.itemGive8`)}</li>
         <li>{t(`privacyPolicy.collectionOfInformation.itemGive9`)}</li>
         <li>{t(`privacyPolicy.collectionOfInformation.otherInfo`)}:
          <ul>
           <li>{t(`privacyPolicy.collectionOfInformation.itemGive10`)}</li>
           <li>{t(`privacyPolicy.collectionOfInformation.itemGive11`)}</li>
          </ul>
         </li>
        </ul>

        <Subtitle2>3. {t(`privacyPolicy.collectionOfInformation.useInfo`)}</Subtitle2>
        <Text>{t(`privacyPolicy.collectionOfInformation.useInfoDescription`)}</Text>
        <Subtitle3>{t(`privacyPolicy.collectionOfInformation.accountAdministration`)}</Subtitle3>


        <ul>
         <li>{t(`privacyPolicy.collectionOfInformation.itemGive12`)}</li>
         <li>{t(`privacyPolicy.collectionOfInformation.itemGive13`)}</li>
         <li>{t(`privacyPolicy.collectionOfInformation.itemGive14`)}</li>
        </ul>


     <Subtitle3>{t(`privacyPolicy.collectionOfInformation.helpCommunicate`)}</Subtitle3>

        <ul>
            <li>{t(`privacyPolicy.collectionOfInformation.itemGive15`)}</li>
            <li>{t(`privacyPolicy.collectionOfInformation.itemGive16`)}</li>
            <li>{t(`privacyPolicy.collectionOfInformation.itemGive17`)}</li>
        </ul>




        <Subtitle3>{t(`privacyPolicy.collectionOfInformation.newServices`)}</Subtitle3>

           <ul>
               <li>{t(`privacyPolicy.collectionOfInformation.itemGive18`)}</li>
               <li>{t(`privacyPolicy.collectionOfInformation.itemGive19`)}</li>
           </ul>

           <Subtitle3>{t(`privacyPolicy.collectionOfInformation.recommendedAds`)}</Subtitle3>

           <ul>
               <li>{t(`privacyPolicy.collectionOfInformation.itemGive20`)}</li>
               <li>{t(`privacyPolicy.collectionOfInformation.itemGive21`)}</li>
           </ul>

           <h3>{t(`privacyPolicy.collectionOfInformation.upgradeOur`)}</h3>

           <ul>
               <li>{t(`privacyPolicy.collectionOfInformation.itemGive22`)}</li>
               <li>{t(`privacyPolicy.collectionOfInformation.itemGive23`)}</li>
               <li>{t(`privacyPolicy.collectionOfInformation.itemGive24`)}</li>
               <li>{t(`privacyPolicy.collectionOfInformation.itemGive25`)}</li>
           </ul>

           <h3>{t(`privacyPolicy.collectionOfInformation.security`)}</h3>

           <ul>
               <li>{t(`privacyPolicy.collectionOfInformation.itemGive26`)}</li>
               <li>{t(`privacyPolicy.collectionOfInformation.itemGive27`)}</li>
               <li>{t(`privacyPolicy.collectionOfInformation.itemGive28`)}</li>
               <li>{t(`privacyPolicy.collectionOfInformation.itemGive29`)}</li>
               <li>{t(`privacyPolicy.collectionOfInformation.itemGive30`)}</li>
           </ul>

           <h3>{t(`privacyPolicy.collectionOfInformation.enforcement`)}</h3>

           <ul>
               <li>{t(`privacyPolicy.collectionOfInformation.itemGive31`)}</li>
               <li>{t(`privacyPolicy.collectionOfInformation.itemGive32`)}</li>
           </ul>

           <Subtitle2>4. {t(`privacyPolicy.collectionOfInformation.shareInformation`)}</Subtitle2>
           <Text>{t(`privacyPolicy.collectionOfInformation.shareInformationDescription`)}</Text>

           <Subtitle3>{t(`privacyPolicy.collectionOfInformation.otherUsers`)}</Subtitle3>
           <Text>{t(`privacyPolicy.collectionOfInformation.otherUsersDescription`)}</Text>

           <Subtitle3>{t(`privacyPolicy.collectionOfInformation.ourPartners`)}</Subtitle3>
           <Text>{t(`privacyPolicy.collectionOfInformation.ourPartnersDescription`)}</Text>

           <Subtitle3>{t(`privacyPolicy.collectionOfInformation.sharingFeatures`)}</Subtitle3>
           <Text>{t(`privacyPolicy.collectionOfInformation.sharingFeaturesDescription`)}</Text>

           <Subtitle3>{t(`privacyPolicy.collectionOfInformation.mvd`)}</Subtitle3>
           <Text>{t(`privacyPolicy.collectionOfInformation.mvdDescription`)}</Text>

           <Subtitle3>{t(`privacyPolicy.collectionOfInformation.legalRights`)}</Subtitle3>
           <Text>{t(`privacyPolicy.collectionOfInformation.legalRightsDescription`)}</Text>

           <Subtitle3>{t(`privacyPolicy.collectionOfInformation.youRequest`)}</Subtitle3>
           <Text>{t(`privacyPolicy.collectionOfInformation.youRequestDescription`)}</Text>

           <Text>{t(`privacyPolicy.collectionOfInformation.infoDescription2`)}</Text>


           <Subtitle2>5. {t(`privacyPolicy.collectionOfInformation.youChoose`)}</Subtitle2>
           <Text>{t(`privacyPolicy.collectionOfInformation.youChooseDescription`)}</Text>

           <ul>
               <li>{t(`privacyPolicy.collectionOfInformation.instrumentAccess`)}</li>
               <li>{t(`privacyPolicy.collectionOfInformation.deviceAccess`)}</li>
               <li>{t(`privacyPolicy.collectionOfInformation.delete`)}</li>
               <li>{t(`privacyPolicy.collectionOfInformation.deleteAccount`)}</li>
           </ul>
           <Subtitle2>6. {t(`privacyPolicy.collectionOfInformation.saveData`)}</Subtitle2>
           <Text>{t(`privacyPolicy.collectionOfInformation.saveDataDescription`)}:</Text>
           <ul>
               <li>{t(`privacyPolicy.collectionOfInformation.itemSave1`)}</li>
               <li>{t(`privacyPolicy.collectionOfInformation.itemSave2`)}:
                   <ul>
                       <li>{t(`privacyPolicy.collectionOfInformation.itemSave3`)}</li>
                       <li>{t(`privacyPolicy.collectionOfInformation.itemSave4`)}</li>
                   </ul>
               </li>
           </ul>


           <Subtitle2>7. {t(`privacyPolicy.collectionOfInformation.securityData`)}</Subtitle2>
           <Text>{t(`privacyPolicy.collectionOfInformation.securityDescription`)}</Text>


           <Subtitle2>8. {t(`privacyPolicy.collectionOfInformation.age`)}</Subtitle2>
           <Text>{t(`privacyPolicy.collectionOfInformation.ageDescription`)}</Text>

          <Subtitle2>8. {t(`privacyPolicy.collectionOfInformation.linksSite`)}</Subtitle2>
           <Text>{t(`privacyPolicy.collectionOfInformation.linksSiteDescription`)}</Text>



           <Subtitle2>9. {t(`privacyPolicy.collectionOfInformation.changePolicy`)}</Subtitle2>
           <Text>{t(`privacyPolicy.collectionOfInformation.changePolicyDescription`)}</Text>

           <Subtitle2>10. {t(`support.contactUs`)}</Subtitle2>
           <ul>
               <li>{t(`support.contactUsDescription`)}:
                   <ul>
                       <li>{t(`support.byEmail`)}: <a style={{color: 'blue'}}>app.gogo.io@gmail.com</a></li>
                   </ul>
               </li>
           </ul>

       </Container>
    )
}

export default PrivacyPolicy

const Container = styled.div`
  margin: 0 auto;
  width: 70%;
  padding: 24px 24px;
  background: white;
  text-wrap: wrap;
  ul li {
    color: #505965;
    margin-top: 12px;
    margin-bottom: 12px;
    font-size: 18px;

  }
  li::marker {
    content: counter(list-item) '. ';
    color: #505965;
    font-weight: bold;
  }
`

const Text = styled.p`
  color: #505965;
  font-size: 18px;
`

const Title = styled.h1`
  color: #21262e;
`
const Subtitle2 = styled.h2`
  color: #21262e;
`
const Subtitle3 = styled.h3`
  color: #21262e;
`
