import React, {useEffect} from 'react'
import styled from 'styled-components'
import {useTranslation, Trans} from "react-i18next";
import {colors} from "../../themes/colors";

const SupportDeleteAccount = () => {
    const {t} = useTranslation();

    useEffect(() => {
        document.title = t(`deleteAccount.title`)
    }, []);

    return (
        <Container>
            <Title>{t(`title`)} – {t(`deleteAccount.instruction`)}</Title>
            <Text>{t(`deleteAccount.description`)}</Text>
            <Subtitle2>{t(`deleteAccount.steps`)}:</Subtitle2>
            <ul>
                <li>{t(`deleteAccount.step1`)}</li>
                <li>{t(`deleteAccount.step2`)}</li>
                <li>{t(`deleteAccount.step3`)}</li>
                <li>{t(`deleteAccount.step4`)}</li>
                <li>{t(`deleteAccount.step5`)}</li>
                <li>{t(`deleteAccount.step6`)}</li>
            </ul>
            <Text>{t(`deleteAccount.end1`)}</Text>
            <Text>
                <Trans
                    components={{ bold: <Link href='mailto:app.gogo.io@gmail.com' /> }}
                    i18nKey={"deleteAccount.end2"}
                    values={{ email: 'app.gogo.io@gmail.com'}}
                /></Text>
              <Text>  <Trans
                  components={{ policy: <Link href={`https://go-go.io/privacy-policy`} /> }}
                  i18nKey={"deleteAccount.end3"}
              /></Text>
        </Container>
    )
}

export default SupportDeleteAccount


const Container = styled.div`
  margin: 0 auto;
  width: 70%;
  padding: 24px 24px;
  background: white;
  text-wrap: wrap;

  ul li {
    color: #505965;
    margin-top: 12px;
    margin-bottom: 12px;
    font-size: 18px;
  }

  li::marker {
    content: counter(list-item) '. ';
    color: #505965;
    font-weight: bold;
  }
`


const Link = styled.a`
  color: ${colors.blue6};
  font-weight: 500;
  font-size: 18px;
`

const Text = styled.p<{ bold?: boolean }>`
  ${(props) =>
          props.bold &&
          `
font-weight: 700;
 `};
  color: #505965;
  font-size: 18px;
  position: relative;
`
const Title = styled.h1`
  color: #21262e;
`
const Subtitle2 = styled.h2`
  color: #21262e;
`
