import React, {useEffect} from 'react'
import styled from 'styled-components'
import {Trans, useTranslation} from "react-i18next";
import { colors } from "../../themes/colors";

const routes = {
    privacyPolicy: `/privacy-policy`,
    termsOfUse: `/terms-of-use`,
    communityGuidelines: `/community-guidelines`,
    safety: `/safety`,
}
const BASE_URL = `https://go-go.io/`
const PRIVACY_POLICY_URL = `${BASE_URL}${routes.privacyPolicy}`
// const TERMS_OF_USE_URL = `${BASE_URL}${routes.termsOfUse}`
const SAFETY_URL = `${BASE_URL}${routes.safety}`
const GUIDELINES_URL = `${BASE_URL}${routes.communityGuidelines}`


const TermsOfUse = () => {
    const { t } = useTranslation();

    useEffect(() => {
        document.title = t(`termsOfUse.title`)
    }, []);

    return (
        <Container>
            <Title>{t(`title`)} – {t(`termsOfUse.title`)}</Title>
            <Text>{t(`privacyPolicy.lastUpdated`, { lastUpdated: `20 мая 2024 г.` })}</Text>
            <Subtitle2>1. {t(`termsOfUse.introduction`)}</Subtitle2>
            <Trans
                components={{ text: <Text/>,  policy: <Link href={PRIVACY_POLICY_URL} />, guidelines: <Link href={GUIDELINES_URL} />, safety: <Link  href={SAFETY_URL} /> }}
                i18nKey={"termsOfUse.introductionDescription"}
            />
            <Text>{t(`termsOfUse.updatedTerms`)}</Text>
            <Text>{t(`termsOfUse.previewDescription1`)}</Text>
            <Text>{t(`termsOfUse.previewDescription2`)}</Text>
            <Text>{t(`termsOfUse.previewDescription3`)}</Text>
            <Trans
                components={{ text: <Text/>,  policy: <Link href={PRIVACY_POLICY_URL} />, guidelines: <Link href={GUIDELINES_URL} />, safety: <Link href={SAFETY_URL} /> }}
                i18nKey={"termsOfUse.previewDescription4"}
            />
            <Text>{t(`termsOfUse.previewDescription5`)}</Text>
            <h2>{t(`termsOfUse.responsibilities`)}</h2>
            <Text bold>{t(`termsOfUse.responsibilitiesDescription`)}</Text>
            <Text bold>{t(`termsOfUse.responsibilitiesDescription2`)}</Text>
            <ul>
                <li>{t(`termsOfUse.item1`)}</li>
                <li>{t(`termsOfUse.item2`)}</li>
                <li>{t(`termsOfUse.item3`)}</li>
            </ul>
            <Text>{t(`termsOfUse.responsibilitiesDescription3`)}</Text>
            <Subtitle3>{t(`termsOfUse.agreeTo`)}:</Subtitle3>
            <ul>
                <li>{t(`termsOfUse.item4`)}</li>
                <li>{t(`termsOfUse.item5`)}</li>
                <li>{t(`termsOfUse.item6`)}</li>
                <li>
                    <Trans
                        components={{ text: <Text/>, safety: <Link  href={SAFETY_URL} /> }}
                        i18nKey={"termsOfUse.item7"}
                    />

                </li>
                <li>
                    <Trans
                        components={{ text: <Text/>, guidelines: <Link href={GUIDELINES_URL} /> }}
                        i18nKey={"termsOfUse.item8"}
                    />
                </li>
                <li>{t(`termsOfUse.item9`)}</li>
            </ul>
            <Subtitle3>{t(`termsOfUse.agreeWillNot`)}:</Subtitle3>
            <ul>
                <li>{t(`termsOfUse.item10`)};</li>
                <li>{t(`termsOfUse.item11`)};</li>
                <li>{t(`termsOfUse.item12`)};</li>
                <li>{t(`termsOfUse.item13`)};</li>
                <li>{t(`termsOfUse.item14`)};</li>
                <li>{t(`termsOfUse.item15`)};</li>
                <li>{t(`termsOfUse.item16`)};</li>
                <li>{t(`termsOfUse.item17`)};</li>
                <li>{t(`termsOfUse.item18`)};</li>
                <li>{t(`termsOfUse.item19`)};</li>
                <li>{t(`termsOfUse.item20`)};</li>
                <li>{t(`termsOfUse.item21`)};</li>
                <li>{t(`termsOfUse.item22`)};</li>
                <li>{t(`termsOfUse.item23`)};</li>
                <li>{t(`termsOfUse.item24`)};</li>
                <li>{t(`termsOfUse.item25`)};</li>
                <li>{t(`termsOfUse.item27`)};</li>
                <li>{t(`termsOfUse.item28`)};</li>
                <li>{t(`termsOfUse.item29`)};</li>
                <li>{t(`termsOfUse.item30`)};</li>
                <li>{t(`termsOfUse.item31`)};</li>
                <li>{t(`termsOfUse.item32`)};</li>
                <li>{t(`termsOfUse.item33`)};</li>
                <li>{t(`termsOfUse.item34`)};</li>
                <li>{t(`termsOfUse.item35`)};</li>
                <li>{t(`termsOfUse.item36`)};</li>
                <li>{t(`termsOfUse.item37`)}.</li>
            </ul>

            <Text>{t(`termsOfUse.stopLicensing`)}</Text>
            <Subtitle2>2. {t(`termsOfUse.content`)}</Subtitle2>
            <Text>{t(`termsOfUse.contentDescription`)}</Text>
            <Subtitle3>{t(`termsOfUse.yourContent`)}</Subtitle3>
            <Text>{t(`termsOfUse.yourContentDescription`)}</Text>

            <Subtitle3>{t(`termsOfUse.yourContent`)}</Subtitle3>
            <Text>{t(`termsOfUse.yourContentDescription`)}</Text>

            <Subtitle3>{t(`termsOfUse.memberContent`)}</Subtitle3>
            <Text>{t(`termsOfUse.memberContentDescription`)}</Text>

            <Subtitle3>{t(`termsOfUse.ourContent`)}</Subtitle3>
            <Text>{t(`termsOfUse.ourContentDescription`)}</Text>

            <Subtitle2>3. {t(`termsOfUse.privacy`)}</Subtitle2>
            <Trans
                components={{ text: <Text/>,  policy: <Link href={PRIVACY_POLICY_URL} /> }}
                i18nKey={"termsOfUse.privacyDescription"}
            />

            <Subtitle2>4. {t(`termsOfUse.rights`)}</Subtitle2>
            <Text>{t(`termsOfUse.rightsDescription`)}</Text>

            <Subtitle2>5. {t(`termsOfUse.rightsGranted`)}</Subtitle2>
            <Text>{t(`termsOfUse.rightsGrantedDescription`)}</Text>

            <Subtitle2>6. {t(`termsOfUse.accountTerminate`)}</Subtitle2>
            <Text>{t(`termsOfUse.accountTerminateDescription`)}</Text>

            <Subtitle2>7. {t(`termsOfUse.european`)}</Subtitle2>
            <Text>{t(`termsOfUse.europeanDescription`)}</Text>

            <Subtitle2>8. {t(`termsOfUse.usa`)}</Subtitle2>
            <Text>{t(`termsOfUse.usaDescription`)}</Text>


            <Subtitle2>9. {t(`termsOfUse.translation`)}</Subtitle2>
            <Text>{t(`termsOfUse.translationDescription`)}</Text>

            <Subtitle2>10. {t(`termsOfUse.disputes`)}</Subtitle2>
            <Text>{t(`termsOfUse.disputesDescription`)}</Text>

            <Subtitle2>11. {t(`termsOfUse.disclaimer`)}</Subtitle2>
            <Text>{t(`termsOfUse.disclaimerDescription`)}</Text>


            <Subtitle2>12. {t(`termsOfUse.ads`)}</Subtitle2>
            <Text>{t(`termsOfUse.adsDescription`)}</Text>

           <Subtitle2>13. {t(`termsOfUse.limitation`)}</Subtitle2>
            <Text>{t(`termsOfUse.limitationDescription`)}</Text>

            <Subtitle2>14. {t(`termsOfUse.indemnity`)}</Subtitle2>
            <Text>{t(`termsOfUse.indemnityDescription`)}</Text>

            <Subtitle2>15. {t(`termsOfUse.acceptanceTerms`)}</Subtitle2>
            <Text>{t(`termsOfUse.acceptanceTermsDescription`)}</Text>


            <Subtitle2>16. {t(`termsOfUse.entireAgreement`)}</Subtitle2>

            <Trans
                components={{ text: <Text/>,  policy: <Link href={PRIVACY_POLICY_URL} />, guidelines: <Link href={GUIDELINES_URL} />, safety: <Link href={SAFETY_URL} /> }}
                i18nKey={"termsOfUse.entireAgreementDescription"}
            />
            <Subtitle2>17. {t(`support.contactUs`)}</Subtitle2>
            <ul>
                <li>{t(`support.contactUsDescription`)}:
                    <ul>
                        <li>{t(`support.byEmail`)}: <Link>app.gogo.io@gmail.com</Link></li>
                    </ul>
                </li>
            </ul>

        </Container>
    )
}

export default TermsOfUse


const Container = styled.div`
        margin: 0 auto;
        width: 70%;
        padding: 24px 24px;
        background: white;
        text-wrap: wrap;
        ul li {
                color: #505965;
                margin-top: 12px;
                margin-bottom: 12px;
                font-size: 18px;

        }
  li::marker {
    content: counter(list-item) '. ';
    color: #505965;
    font-weight: bold;
  }
`

const Text = styled.p<{bold?: boolean}>`
  ${(props) =>
          props.bold &&
          `
font-weight: 700;
 `};
  color: #505965;
  font-size: 18px;
`

const Link = styled.a`
  color: ${colors.blue6};
  font-weight: 500;
`

const Title = styled.h1`
  color: #21262e;
`
const Subtitle2 = styled.h2`
  color: #21262e;
`
const Subtitle3 = styled.h3`
  color: #21262e;
`
